import { createContext, useContext } from "react";
import { XMLTemplateContextProviderV1 } from "./XMLTemplate/V1";

export const XMLTemplateContext = createContext({});

export const XMLTemplateContextProvider = (({ t: __, children }) => {
  return (
    <XMLTemplateContextProviderV1 XMLTemplateContext={XMLTemplateContext}>
      {children}
    </XMLTemplateContextProviderV1>
  );
});

export const useXMLTemplate = () => useContext(XMLTemplateContext);
