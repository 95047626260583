import { useContext, useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { withTranslation } from 'react-i18next';

import { Snack, initialSnackState, snackEvent } from "../../atoms/Snack";
import { AppContext } from "../../context-providers/App";
import { useXMLTemplate } from "../../context-providers/XMLTemplate";
import useAuth from '../../context-providers/Auth';
import { ExaminationContext } from "../../context-providers/Examination";
import { MeasurementsContext } from '../../context-providers/Measurements';

import { formatName } from '../../services/examination';
import ResourceApi from '../../services/resource';

import ExaminationReportPrint from './ExaminationReportPrint';
import ExaminationReportView from './ExaminationReportView';
import ExaminationReportTemplate from './templatePrint/Template';
import LoaderInline from "../../atoms/LoaderInline/LoaderInline"

import { ExamStatus } from '../../config';

const buildMultipartForm = (html, json) => {
  // Build a form with the HTML content as blob 
  const formData = new FormData();
  const html_blob = new Blob([html], { type: "text/html" });
  formData.append("html", html_blob);
  if (json) {
    const json_blob = new Blob([JSON.stringify(json)], { type: "text/html" });
    formData.append("json", json_blob);
  }

  return formData;
}

const ExaminationReport = ({ t: __ }) => {
  const examinationContext = useContext(ExaminationContext);

  const appContext = useContext(AppContext);
  const {
    loaded,
    reportData,
    setReportData,
    placeholders,
    customPlaceholders,
    setCustomPlaceholders,
    checkCondition,
    loadDynamicReportData,
    getPlaceholderWithProps,
    getCarryForwardWithProps,
    applyAutomationTemplate,
    automationTemplateFieldsVisible,
    setAutomationTemplateFieldsVisible,
    componentChecklistAssoc,
  } = useXMLTemplate();

  const measurementsContext = useContext(MeasurementsContext)

  const { isFeatureFlagEnabled, siteFlowsConnectors, user } = useAuth();

  const [snack, setSnack] = useState(initialSnackState({}));

  const [uploadingReportAsPDF, setUploadingReportAsPDF] = useState(false);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [base64Images, setBase64Images] = useState([]);

  const [submitDialogIsOpen, setSubmitDialogIsOpen] = useState(false);
  const [reviewReportDialogIsOpen, setReviewReportDialogIsOpen] = useState(false);
  const [stakeholdersDialogIsOpen, setStakeholdersDialogIsOpen] = useState(false);
  const [manageMeasurementsIsOpen, setManageMeasurementsIsOpen] = useState(false);
  const [previewExamReportDialogIsOpen, setPreviewExamReportDialogIsOpen] = useState(false);
  const [editChecklistDialogIsOpen, setEditChecklistDialogIsOpen] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  /* contains null or an Object with information to perform the sign event
   * except the HTML.
   */
  const [signingReport, setSigningReport] = useState(null);

  const templateBlueprint = examinationContext.debugTemplate || reportData?.report_template?.blueprint;
  const examEvents = examinationContext.examination.events;

  const downloadReportAsPDF = async () => {
    setDownloadingPDF(true)

    const HTML = renderReportHtml();

    ResourceApi.getPdfFromHtmlChrome(buildMultipartForm(HTML)).then(response => {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const date = new Date().toJSON().slice(0, 16).replace(/[T|:]/g, "-");
      const patientName = examinationContext.patient?.name ? formatName(examinationContext.patient.name).fullName : __("patients.anonymous");
      const fileName = `Sonio-${date}-${patientName}.pdf`;
      const a = document.createElement('a');
      let blob = new Blob([response.data]);
      blob = blob.slice(0, blob.size, "application/pdf");
      a.href = window.URL.createObjectURL(blob);
      if (!isMobile) a.setAttribute('download', fileName);
      a.click();
      a.remove();

      setDownloadingPDF(false)
      setPreviewExamReportDialogIsOpen(false);
    });
  };

  useEffect(() => {
    const user_timezone = examinationContext.examination.site?.timezone;

    if (!signingReport)
      return
    /* The report Data has not yet been updated */
    if (!reportData.examination_data.status === ExamStatus.REPORT_SIGNED)
      return
    /* The placeholders has not yet been updated */
    if (!placeholders["examination.signed"])
      return

    const HTML = renderReportHtml();
    examinationContext.signReport(buildMultipartForm(HTML, {
      comment: signingReport.comment,
      user_timezone,
    }))
      .then((r) => {
        signingReport.resolve(r);
      })
      .catch((error) => {
        signingReport.reject(error)
      })
      .finally(() => {
        setSigningReport(null);
      })
  }, [
    signingReport,
    reportData?.examination_data?.status,
    JSON.stringify(placeholders["examination.signed"])
  ])

  const renderHTML = () => {
    return renderReportHtml();
  }


  const uploadReportAsPDF = async () => {
    if (uploadingReportAsPDF) return;
    const HTML = renderReportHtml();

    ResourceApi.sharePdfFromHtmlChrome(buildMultipartForm(HTML)).then(response => {
      snackEvent("show", setSnack, { type: "success", assigns: { state: "uploaded" }, hideAfter: 3000 });
    }).catch(() => {
      snackEvent("show", setSnack, { type: "error", assigns: { state: "error" }, hideAfter: 5000 });
    }).finally(() => setUploadingReportAsPDF(false));
  };

  const renderReportHtml = () => renderToStaticMarkup(
    <ExaminationReportTemplate>
      <ExaminationReportPrint
        reportData={reportData}
        placeholders={placeholders}
        customPlaceholders={customPlaceholders}
        setCustomPlaceholders={setCustomPlaceholders}
        getPlaceholderWithProps={getPlaceholderWithProps}
        getCarryForwardWithProps={getCarryForwardWithProps}
        base64Images={base64Images}
        appContext={appContext}
        examinationContext={examinationContext}
        checkCondition={checkCondition}
        isFeatureFlagEnabled={isFeatureFlagEnabled}
        measurementsContext={measurementsContext}
      />
    </ExaminationReportTemplate>
  );

  const snackMessage = ({ state }) => {
    switch (state) {
      case "uploaded":
        return "Report uploaded successfully";
      case "error":
        return "Unable to upload your report. (Retry later)";
    }
  };

  /* return a promise that is resolved when the report is signed for real */
  const signReport = (comment) => {
    if (!!signingReport) {
      return new Promise((resolve, reject) => reject("signature already in progress"))
    }
    if (!!reportData.examination_data.status === 'report_signed') {
      return new Promise((resolve, reject) => reject("report already signed"))
    }
    return new Promise((resolve, reject) => {
      const inserted_at = new Date()
      const { examination_data } = structuredClone(reportData);
      const newReportData = { examination_data };

      setSigningReport({ resolve, reject, inserted_at, comment });
      ResourceApi.getFrozenDateTime(reportData.examination_data.id).then((r) => {
        newReportData.examination_data.status = ExamStatus.REPORT_SIGNED;
        newReportData.examination_data.signed_data = {};
        newReportData.examination_data.signed_data.inserted_at = inserted_at.toISOString();

        const frozenDate = r.data.data.date;
        const frozenTime = r.data.data.time;

        newReportData.examination_data.signed_data.frozen_inserted_at = {
          date: frozenDate,
          time: frozenTime,
        };
        newReportData.examination_data.signed_data.entity_title = user.title;
        /* This will trigger a rerender that will call the useEffect actually signing the report */
        setReportData(newReportData)

        /* Here we ensure the clean-up catch are exectued */
      }).catch((r) => reject(r))

    }).catch((error) => {
      setReportData(reportData);
      setSigningReport(null);
      throw error;
    });
  };

  /* return a promise that is resolved when the submition is a success */
  const submitReport = (comment, event_type) => {
    const user_timezone = examinationContext.examination.site.timezone;
    if (event_type == ExamStatus.REPORT_SIGNED) {
      return signReport(comment)
    } else {
      return examinationContext.submitReport({ comment, event_type, user_timezone });
    }
  };

  /* TODO: Only here for debug purposes. Needs to be removed when the examination sign
   * workflow is proven as completly fixed */
  const saveReport = () => {
    if (reportData?.examination_data?.status !== ExamStatus.REPORT_SIGNED)
      return console.error("Can not save unsigned examination")
    const HTML = renderReportHtml();
    return ResourceApi.saveReport(examinationContext.examination.id, buildMultipartForm(HTML));
  }
  window.saveReport = saveReport;

  const afterAssociatingContactPoint = (contactPoint) => {
    /* optimistic update */
    const newReportData = structuredClone(reportData);
    newReportData.examination_data.associated_contact_points.push(contactPoint);
    setReportData(newReportData);
    loadDynamicReportData();
  }

  const afterDeassociatingContactPoint = (contactPointId) => {
    /* optimistic update */
    const newReportData = structuredClone(reportData);
    newReportData.examination_data.associated_contact_points = newReportData.examination_data.associated_contact_points?.filter(cp => cp.id !== contactPointId);
    setReportData(newReportData);
    loadDynamicReportData();
  }

  const getError = () => {
    if (!examinationContext.examination?.patient_id) return __("report.error.anonymousExam");
    if (!examinationContext.examination?.episode) return __("report.error.missingEpisode");
  }

  const createAutomationTemplate = (attrs) => {
    if (examinationContext.examination?.preset) {
      ResourceApi.createAutomationTemplate(
        examinationContext.examination.preset.id,
        attrs
      ).then((resp) => loadDynamicReportData() // Reload the automation templates
      ).catch((error) => console.error(error));
    } else {
      console.error("Cannot create flash report without examination preset.", attrs);
    }
  }

  if (!examEvents)
    return null;

  return (loaded) ? (
    <>
      <Snack snack={snack} setSnack={setSnack}>
        {snackMessage(snack.assigns)}
      </Snack>
      <ExaminationReportView
        isFeatureFlagEnabled={isFeatureFlagEnabled}
        templateBlueprint={templateBlueprint}
        reportData={reportData}
        downloadReportAsPDF={downloadReportAsPDF}
        downloadingPDF={downloadingPDF}
        uploadReportAsPDF={siteFlowsConnectors?.upload_report?.length ? uploadReportAsPDF : null}
        uploadingReportAsPDF={uploadingReportAsPDF}
        submitReport={submitReport}
        submitDialogIsOpen={submitDialogIsOpen}
        setSubmitDialogIsOpen={setSubmitDialogIsOpen}
        reviewReportDialogIsOpen={reviewReportDialogIsOpen}
        setReviewReportDialogIsOpen={setReviewReportDialogIsOpen}
        stakeholdersDialogIsOpen={stakeholdersDialogIsOpen}
        setStakeholdersDialogIsOpen={setStakeholdersDialogIsOpen}
        manageMeasurementsIsOpen={manageMeasurementsIsOpen}
        setManageMeasurementsIsOpen={setManageMeasurementsIsOpen}
        previewExamReportDialogIsOpen={previewExamReportDialogIsOpen}
        setPreviewExamReportDialogIsOpen={setPreviewExamReportDialogIsOpen}
        editChecklistDialogIsOpen={editChecklistDialogIsOpen}
        setEditChecklistDialogIsOpen={setEditChecklistDialogIsOpen}
        commentValue={commentValue}
        setCommentValue={setCommentValue}
        examStatus={examinationContext.examination.status}
        examEvents={examEvents}
        appContext={appContext}
        checkCondition={checkCondition}
        renderHTML={renderHTML}
        associateContactPoint={(params) => examinationContext.associateContactPoint(params, afterAssociatingContactPoint)}
        deassociateContactPoint={(association_cp_id) => examinationContext.deassociateContactPoint(association_cp_id, afterDeassociatingContactPoint)}
        applyAutomationTemplate={applyAutomationTemplate}
        automationTemplateFieldsVisible={automationTemplateFieldsVisible}
        setAutomationTemplateFieldsVisible={setAutomationTemplateFieldsVisible}
        createAutomationTemplate={createAutomationTemplate}
        componentChecklistAssoc={componentChecklistAssoc}
        base64Images={base64Images}
        setBase64Images={setBase64Images}
      />
    </>
  ) : (
    !!getError() ? <ExaminationReportError error={getError()} /> : <ExaminationReportLoading />
  );
};

export default withTranslation()(ExaminationReport);



const ExaminationReportLoading = ({ }) => {

  return <div className="exam-report-container exam-report-loading">
    <LoaderInline />
  </div>;
}

const ExaminationReportError = ({ error }) => {
  return <div className="exam-report-container exam-report-error">
    {error}
  </div>;
};

