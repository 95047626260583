import { isValidElement, cloneElement } from "react";
import { renderToStaticMarkup } from 'react-dom/server';

export default function ReportTemplateBlock({ defaultKey = '', children, props, fetus = 1, reportMode }) {
  const { layout, background, width = null, align = null, size = "", outlined = null, "avoid-break": avoidBreak, "auto-hide": autoHide } = (props ?? {});

  const style = {
    minWidth: width ? `min(${width}, 100%)` : "auto",
    maxWidth: width ? `min(${width}, 100%)` : "100%",
  };

  if (align) style.textAlign = align;

  const sizes = {
    xl: 1,
    l: 2,
    m: 3,
    s: 4,
    xs: 5,
    xxs: 6,
  };

  const childrenWithFetusNumber = Array.isArray(children) ? children.map((child, i) => {
    if (isValidElement(child)) {
      const key = `${defaultKey || ""}_${i}_block_${fetus}`;
      return cloneElement(child, { ...props, fetus, defaultKey: key, key });
    }
    return child;
  }) : children;

  const notPrintable = reportMode === "print" && autoHide && !renderToStaticMarkup(childrenWithFetusNumber).includes('has-printable-value');

  return (
    <div className={`block size-${sizes[size] || "default"} ${outlined ? 'is-outlined' : ''} ${avoidBreak ? 'avoid-break' : ''} ${(notPrintable) ? 'not-printable' : ''} ${background ? 'has-background' : ''}`} style={style} data-layout={layout}>
      {!notPrintable && childrenWithFetusNumber}
    </div>
  );
}
