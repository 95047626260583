import { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import useAuth from '../../../context-providers/Auth';
import { ExaminationContext } from "../../../context-providers/Examination";
import ResourceApi from "../../../services/resource";
import { isNullOrUndefined } from '../../../utils';
import Table from './Table';
import Column from './Empty';
import Value from './Value';
import Button from '../../../atoms/Button/Button';
import Icon from '../../../atoms/Icon/Icon';
import "./Coding.css";

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function getQuestionAdditionalStatement({ trimester }) {
  /* This is a quick dirty fix for taking in count the GA in IMO search
   * We must improve this in the future with a more specified search tags
   */
  switch (trimester) {
    case "T1":
      return "first trimester"
    case "T2":
      return "second trimester"
    case "T3":
      return "third trimester"
    default:
      return ""
  }
}

const Coding = ({
  t: __,
  showErrors,
  placeholder,
  examinationContext,
  user,
  canEdit,
  ...otherProps
}) => {
  const { examination, updateExamination } = useContext(ExaminationContext);
  const [defaultProceduresCodes, setDefaultProceduresCodes] = useState([]);
  const [diagnosticSuggestions, setDiagnosticSuggestions] = useState([]);

  const { siteFlowsConnectors } = useAuth();

  useEffect(() => {
    ResourceApi.getDefaultProceduresCodes(examination.id)
      .then((resp) => setDefaultProceduresCodes([{ codes: resp.data.data }]))
      .catch((e) => console.error(e))
  }, [examination.preset_id, JSON.stringify(placeholder.methods?.value)])

  useEffect(() => {
    const indicationsSuggestions = Object.values({ ...(placeholder.indications?.value || {}) })
      .filter(item => Array.isArray(item.codes_object))
      .map(({ codes_object, label }) => ({
        codes: (Array.isArray(codes_object) ? codes_object : Object.entries(codes_object || {}))?.map(({ code, description, origin_value }) => ({
          code,
          description,
          origin_value,
          origin: "indication"
        })),
        description: label
      }));

    const findingsSuggestions = Object.values({ ...(placeholder.findings?.value || {}) })
      .filter(item => Array.isArray(item.codes_object))
      .map(({ codes_object, label }) => ({
        codes: codes_object.map(({ code, description, origin_value }) => ({
          code,
          description,
          origin_value,
          origin: "finding"
        })),
        description: label
      }));

    ResourceApi.suggestCode(
      "diagnostic",
      /* TODO This does not support the real suggestions
      dropdownToList(placeholder.indications),
      dropdownToList(placeholder.findings),
      */
      [],
      [],
      getQuestionAdditionalStatement(examination)
    )
      .then(({ data }) => setDiagnosticSuggestions(
        data.indications
          .concat(data.findings)
          .concat(findingsSuggestions)
          .concat(indicationsSuggestions)
      ))
      .catch((e) => console.error(e))
    /* TODO how do we show the user something wrong is going on with Sonio or IMO */
  }, [JSON.stringify({ indications: placeholder.indications?.value, findings: placeholder.findings?.value })])

  const filterSuggestions = (suggestions, codes) => {
    const groupedSuggestions = Object.groupBy(
      (suggestions
        .flatMap(suggestion => suggestion.codes.map(c => ({ id: uuidv4(), ...c, origin_value: suggestion.description })))
      ),
      ({ code }) => code)

    const groupedCodes = Object.groupBy(codes, ({ code }) => code)

    return Object.entries(groupedSuggestions)
      .flatMap(([code, suggestions]) => {
        return suggestions.slice((groupedCodes[code] || []).length)
      })
  }

  const diagnosticSuggestionsFiltered = filterSuggestions(diagnosticSuggestions, examinationContext?.examination?.["diagnoses_codes"] || []);
  const defaultProceduresCodesFiltered = filterSuggestions(defaultProceduresCodes, examinationContext?.examination?.["procedures_codes"] || []);

  const update = async (attributes) => {
    if (!canEdit)
      return
    return await updateExamination({
      ...attributes,
      id: examination.id,
      status: (examination.status === "draft" || examination.status === "inprogress") ? "inprogress" : "completed"
    })
  }

  const addAllSuggestedCodes = async () => {
    return await update({
      diagnoses_codes: [...examinationContext.examination?.diagnoses_codes, ...diagnosticSuggestionsFiltered],
      procedures_codes: [...examinationContext.examination?.procedures_codes, ...defaultProceduresCodesFiltered]
    })
  }

  const addCode = async (examinationAttribute, item) => {
    await update({ [examinationAttribute]: [...examinationContext.examination?.[examinationAttribute], item] });
  }

  if (!siteFlowsConnectors?.coding)
    return null

  const numberOfSuggestions = diagnosticSuggestionsFiltered.length + defaultProceduresCodesFiltered.length;


  return (
    <div className="exam-report-coding">
      <div className="exam-report-coding_heading">
        <h1>{__("reportCoding.title")}</h1>
        {!!numberOfSuggestions && (
          <Button onClick={addAllSuggestedCodes} label={__("reportCoding.addAllSuggestedCodes", { count: numberOfSuggestions })} icon="add" size="small" />
        )}
      </div>
      {examination && (
        <>
          <CodingSection
            coding_system="diagnostic"
            examinationAttribute="diagnoses_codes"
            source="ICD10"
            codes={examinationContext?.examination?.["diagnoses_codes"]}
            showErrors={showErrors}
            mandatory={examination?.preset?.is_coding_mandatory}
            suggestions={diagnosticSuggestionsFiltered}
            examinationContext={examinationContext}
            user={user}
            addCode={addCode}
            update={update}
            canEdit={canEdit}
            {...otherProps}
          />
          <CodingSection
            coding_system="procedure"
            examinationAttribute="procedures_codes"
            source="CPT"
            codes={examinationContext?.examination?.["procedures_codes"]}
            showErrors={showErrors}
            mandatory={examination?.preset?.is_coding_mandatory}
            suggestions={defaultProceduresCodesFiltered}
            examinationContext={examinationContext}
            user={user}
            addCode={addCode}
            update={update}
            canEdit={canEdit}
            {...otherProps}
          />
        </>
      )}
    </div>
  )
}

export default withTranslation()(Coding);


const CodingSection = withTranslation()(({
  t: __,
  coding_system,
  examinationAttribute,
  source,
  codes = [],
  showErrors,
  mandatory,
  addCode,
  update,
  suggestions = [],
  examinationContext,
  user,
  canEdit,
  ...otherProps
}) => {

  const placeholder = {
    value: Object.fromEntries(codes?.filter(code => code).map((code, order) => [code.id || code.code + '_' + code.description, {
      value: true,
      code: code.code,
      label: code.description,
      description: code.description,
      order,
      source: code.origin,
      originalItem: code,
    }])),
  }

  /* Take returns of the Table component and makes it a code understandable data
  */
  const itemsToCodes = (items) => {
    return Object.values(items?.value || {})
      .sort((a, b) => a.order - b.order)
      .flatMap((item, itemIndex) => {
        if (!isNullOrUndefined(item.originalItem)) {
          return [item.originalItem]
        }
        if (!isNullOrUndefined(item.codes_object)) {
          return item.codes_object.map((code) => ({ ...code, id: uuidv4() }))
        }
      })
  }

  const onEndEditing = (data, items) => {
    update({ [examinationAttribute]: itemsToCodes(items) })
  }

  return (
    <div className="exam-report-coding-section">
      <div className="exam-report-coding-section_title">
        <h2>{__("reportCoding.system." + coding_system)}</h2>
      </div>
      <div className="exam-report-coding-section_table">
        <Table
          {...otherProps}
          props={{ data: `coding.${coding_system}`, sortable: "true", source, examinationContext, required: `${mandatory}`, "empty-message": __("reportCoding.nothingToShow"), "search-placeholder": __("reportCoding.searchPlaceholder." + coding_system) }}
          canEdit={canEdit}
          onEndEditing={onEndEditing}
          user={user}
          placeholder={placeholder}
          examinationContext={examinationContext}
          showErrors={showErrors}
        >
          {[
            <Column key="col1" props={{ label: __("reportCoding.table.code"), width: "10%" }} type="column">
              {[
                <Value key="value1" props={{ data: `coding.${coding_system}`, attribute: "code" }} placeholder={placeholder} />,
              ]}
            </Column>,
            <Column key="col2" props={{ label: __("reportCoding.table.description") }} type="column">
              {[
                <Value key="value2" props={{ data: `coding.${coding_system}`, attribute: "label" }} placeholder={placeholder} />,
              ]}
            </Column>,
          ]}
        </Table>
        <SuggestionsSection
          suggestions={suggestions}
          addCode={addCode}
          examinationAttribute={examinationAttribute}
          coding_system={coding_system}
        />
      </div>
    </div>
  )
});

const SuggestionsSection = withTranslation()(({
  t: __,
  suggestions,
  addCode,
  examinationAttribute,
  coding_system
}) => {

  const uniqueSuggestions = [...new Map(suggestions.map(item => [item.code + item.description, item])).values()];

  return (
    !!uniqueSuggestions?.length && (
      <div className="exam-report-coding-section_suggestions">
        <h6 className="section-title">{__("reportCoding.suggestionSentence." + coding_system)}</h6>
        {uniqueSuggestions?.map((suggestion, i) => (
          <div
            key={i}
            onClick={() => addCode(examinationAttribute, suggestion)}
            className="exam-report-coding-section_suggestion"
          >
            <Icon name="add" />
            <span className="code">{suggestion.code}</span>
            <span className="meta">
              <span className="description">{suggestion.description}</span>
              {!!suggestion.origin_value && suggestion.origin_value !== suggestion.description && (
                <span className="suggested">{suggestion.origin_value}</span>
              )}
            </span>
          </div>
        ))}
      </div>
    )
  );
});
