import XMLToReact from 'xml-to-react';
import useAuth from "../../context-providers/Auth";

import ReportTemplatePageFooter from './templatePrint/PageFooter';
import ReportTemplatePageHeader from './templatePrint/PageHeader';
import ReportTemplateHeader from './templatePrint/Header';
import ReportTemplateImages from './templatePrint/Images';
import ReportTemplateDocument from './templatePrint/Document';
import ReportTemplatePrivate from './templatePrint/Private';
import ReportTemplateBlock from './templatePrint/Block';
import ReportTemplateHeading from './templatePrint/Heading';

import ReportTemplateChecklist from '../../components/XMLDocument/template/Checklist';
import ReportTemplateContent from '../../components/XMLDocument/template/Content';
import ReportTemplateGaTable from '../../components/XMLDocument/template/GaTable/GaTable';
import ReportTemplateLabel from '../../components/XMLDocument/template/Label';
import ReportTemplateMeasurementCurve from '../../components/XMLDocument/template/MeasurementCurve';
import ReportTemplateMeasurementCurveLegend from '../../components/XMLDocument/template/MeasurementCurveLegend';
import ReportTemplateBiometryTable from '../../components/XMLDocument/template/BiometryTable';
import ReportTemplateBiometryItem from '../../components/XMLDocument/template/BiometryItem';
import ReportTemplateFetalGrowthTable from '../../components/XMLDocument/template/FetalGrowthTable';
import ReportTemplatePageBreak from '../../components/XMLDocument/template/PageBreak';
import ReportTemplateSpacer from '../../components/XMLDocument/template/Spacer';
import ReportTemplateLogo from '../../components/XMLDocument/template/Logo';
import ReportTemplateTextBlock from '../../components/XMLDocument/template/TextBlock';
import ReportTemplateMedicalHistory from '../../components/XMLDocument/template/MedicalHistory';
import ReportTemplateValue from '../../components/XMLDocument/template/Value';
import ReportTemplateCondition from '../../components/XMLDocument/template/Condition';
import ReportTemplateIf from '../../components/XMLDocument/template/If';
import ReportTemplateElse from '../../components/XMLDocument/template/Else';
import ReportTemplateImage from '../../components/XMLDocument/template/Image';
import ReportTemplateDropdown from '../../components/XMLDocument/template/Dropdown';
import ReportTemplateOption from '../../components/XMLDocument/template/Option';
import ReportTemplateFetus from '../../components/XMLDocument/template/Fetus';
import ReportTemplateTable from '../../components/XMLDocument/template/Table';
import ReportTemplateEmpty from '../../components/XMLDocument/template/Empty';
import ReportTemplateGraphs from '../../components/XMLDocument/template/Graphs';
import ReportTemplateSearch from '../../components/XMLDocument/template/Search';
import IdentifierBiometry from '../../components/XMLDocument/template/IdentifierBiometry';

const ExaminationReportPrint = ({
  reportData,
  placeholders,
  getPlaceholderWithProps,
  zones,
  base64Images,
  appContext,
  examinationContext,
  measurementsContext,
  checkCondition,
  isFeatureFlagEnabled,
  getDocumentPlaceholder = () => { },
  startEditing = () => { },
  onEndEditingChecklist = () => { },
  onEndEditing = () => { },
  onEndEditingDating = () => { },
  setPlaceholders = () => { },
  setAssignedGa = () => { },
  revertAssignedGa = () => { },
  updateEpisode = () => { },
  getCarryForwardWithProps = () => { },
}) => {

  const templateBlueprint = examinationContext.debugTemplate || reportData?.report_template?.blueprint;
  const editingFieldId = false;
  const startEditingField = () => { };
  const setBase64Images = () => { };
  const requiredAndInvalid = false;
  const setRequiredAndInvalid = () => { };
  const showErrors = false;
  const { user } = useAuth();

  let fetusNumber = 1;
  const numberOfFetuses = placeholders["patient.nb_fetuses"]?.value || 0;
  const fetusNames = placeholders["fetus.name"]?.value || [];
  const fetuses = [{id: null}].concat(examinationContext.examination.fetuses)

  const commonProps = {
    reportMode: "print",
    user,
    getPlaceholderWithProps,
    reportData,
    appPreferences: appContext?.preferences,
    examinationContext,
    canEdit: examinationContext.canEdit,
    requiredAndInvalid,
    setRequiredAndInvalid,
    showErrors,
    onEndEditing,
    numberOfFetuses,
    fetuses,
    fetusNames,
    measurementsContext
  };
  
  const checklistItemsToDisplay = Object.entries(placeholders).filter(([key, value]) => key.startsWith("checklist.item.") && value.some(valuePerFetus => valuePerFetus.active)).map(item => item[1]);

  const checklistProps = {
    placeholders,
    groups: reportData.checklist_item_groups,
    checklistItems: checklistItemsToDisplay,
    getPlaceholderWithProps,
    zones,
    onEndEditingChecklist
  }

  const xmlToReact = new XMLToReact({
    snippet: (props) => ({ type: ReportTemplateEmpty, props: { props, type: "snippet", ...commonProps } }),
    section: (props) => ({ type: ReportTemplateEmpty, props: { props, type: "section", ...commonProps } }),
    block: (props) => ({ type: ReportTemplateBlock, props: { props, ...commonProps } }),
    checklist: (props) => ({ type: ReportTemplateChecklist, props: { props, ...checklistProps, ...commonProps } }),
    column: (props) => ({ type: ReportTemplateEmpty, props: { props, type: "column", showOptions: false, ...commonProps } }),
    content: (props) => ({ type: ReportTemplateContent, props: { props, placeholder: getPlaceholderWithProps(props, "content"), ...commonProps } }),
    document: (props) => ({ type: ReportTemplateDocument, props: { props, startEditing, placeholder: getDocumentPlaceholder(), ...commonProps } }),
    dropdown: (props) => ({ type: ReportTemplateDropdown, props: { props, placeholder: getPlaceholderWithProps(props), ...commonProps } }),
    fetus: (props) => ({ type: ReportTemplateFetus, props: { props, ...commonProps } }),
    group: (props) => ({ type: ReportTemplateEmpty, props: { props, type: "group", ...commonProps } }),
    option: (props) => ({ type: ReportTemplateOption, props: { props, type: "option", ...commonProps } }),
    condition: (props) => ({ type: ReportTemplateCondition, props: { props, getPlaceholderWithProps, checkCondition, ...commonProps } }),
    if: (props) => ({ type: ReportTemplateIf, props: { props, getPlaceholderWithProps, type: "if", checkCondition, ...commonProps } }),
    else: (props) => ({ type: ReportTemplateElse, props: { props, type: "else", ...commonProps } }),
    image: (props) => ({ type: ReportTemplateImage, props: { props } }),
    images: (props) => ({ type: ReportTemplateImages, props: { props, placeholder: getPlaceholderWithProps(props), base64Images, setBase64Images } }),
    label: (props) => ({ type: ReportTemplateLabel, props: { props } }),
    logo: (props) => ({ type: ReportTemplateLogo, props: { props, url: placeholders["logo.url"] } }),
    graphs: (props) => ({ type: ReportTemplateGraphs, props: { props, ...commonProps } }),
    "measurement-curve": (props) => ({ type: ReportTemplateMeasurementCurve, props: { props, type: "measurement-curve", reportData, placeholder: placeholders[`measurement.${props.data}`], placeholders, editingFieldId, startEditingField, onEndEditing, ...commonProps } }),
    "measurement-curve-legend": (props) => ({ type: ReportTemplateMeasurementCurveLegend, props: { props, type: "measurement-curve-legend", numberOfFetuses, names: fetusNames, fetusNumber } }),
    "ga-table": (props) => ({ type: ReportTemplateGaTable, props: { props, placeholders, setPlaceholders, currentExamDate: reportData?.examination_data?.examination_date, onEndEditingDating, setAssignedGa, revertAssignedGa, updateEpisode, ...commonProps, print: true } }),
    "biometry-table": (props) => ({ type: ReportTemplateBiometryTable, props: { props, numberOfFetuses, fetusNumber, fetusNames } }),
    "biometry-item": (props) => ({ type: ReportTemplateBiometryItem, props: { props, reportData, placeholder: placeholders[`measurement.${props.data}`], placeholders, fetusNumber, numberOfFetuses, fetusNames, editingFieldId, startEditingField, ...commonProps } }),
    "identifier-biometry": (props) => ({ type: IdentifierBiometry, props: { props, placeholders, ...commonProps } }),
    "fetal-growth-table": (props) => ({ type: ReportTemplateFetalGrowthTable, props: { props, reportData, placeholders, fetusNumber, numberOfFetuses, fetusNames, appPreferences: appContext?.preferences, print: true, ...commonProps } }),
    header: (props) => ({ type: ReportTemplateHeader, props: { props, reportData } }),
    heading: (props) => ({ type: ReportTemplateHeading, props: { props } }),
    "page-break": (props) => ({ type: ReportTemplatePageBreak, props: { props } }),
    private: (props) => ({ type: ReportTemplatePrivate, props: { props } }),
    search: (props) => ({ type: ReportTemplateSearch, props: { props, placeholder: getPlaceholderWithProps(props), carryForward: getCarryForwardWithProps(props), ...commonProps } }),
    spacer: (props) => ({ type: ReportTemplateSpacer, props: { props } }),
    table: (props) => ({ type: ReportTemplateTable, props: { props, placeholder: getPlaceholderWithProps(props, "table"), carryForward: getCarryForwardWithProps(props, "table"), showOptions: false, ...commonProps } }),
    textblock: (props) => ({ type: ReportTemplateTextBlock, props: { props, placeholder: getPlaceholderWithProps(props, "textarea"), ...commonProps } }),
    "medical-history": (props) => ({ type: ReportTemplateMedicalHistory, props: { props, medicalHistory: reportData.medical_history, isFeatureFlagEnabled } }),
    value: (props) => ({ type: ReportTemplateValue, props: { props, placeholder: getPlaceholderWithProps(props), ...commonProps } }),
    "page-footer": (props) => ({ type: ReportTemplatePageFooter, props: { props } }),
    "page-header": (props) => ({ type: ReportTemplatePageHeader, props: { props } }),
  });
  const templateReactTree = xmlToReact.convert(templateBlueprint);

  return templateReactTree;
}

export default ExaminationReportPrint;
