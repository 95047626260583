import { default as React, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import Button from "../../../atoms/Button/Button";
import Icon from "../../../atoms/Icon/Icon";
import LoaderInline from "../../../atoms/LoaderInline/LoaderInline";
import SelectInput from "../../../atoms/SelectInput/SelectInput";
import DetectionScore from "../../../components-dt/DetectionScore/DetectionScore";
import FavoriteButton from "../../../components/ExamMediaView/FavoriteButton/FavoriteButton";
import Popup from "../../../components/Popup/Popup";
import SlideDrawing from "../../../components/Slide/SlideDrawing/SlideDrawing";
import config from "../../../config";
import { WindowContext } from "../../../context-providers/Window";
import { AppContext } from "../../../context-providers/App";
import useAuth from "../../../context-providers/Auth";
import { DxContext } from "../../../context-providers/Dx";
import { ExaminationContext } from "../../../context-providers/Examination";
import ResourceApi from "../../../services/resource";
import { getInstanceThumbnailUri, isNullOrUndefined, formatCheckListItemLabel, getInstanceVideoUri} from "../../../utils";
import "./SlidePanel.css";

import GlobalSearchBar from "../../GlobalSearchBar/GlobalSearchBar";
import SlidePanelVideo from "./SlidePanelVideo";
import SmartMatch from "./SmartMatch";

const SlidePanel = ({
  t: __,
  plane,
  slides,
  filteredSlides,
  extractedFrames,
  checklistItems,
  anomalies,
  trimester,
  setSelectedPlaneByKey,
  setSelectedPlaneByMediaId,
  setShowSlideBrowser,
  setLastMatchedSlide = () => { },
  onClose = () => { }
}) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const windowContext = useContext(WindowContext);
  const examinationContext = useContext(ExaminationContext);
  const appContext = useContext(AppContext);
  const dxContext = useContext(DxContext);

  const { isFeatureFlagEnabled } = useAuth();
  const history = useHistory();

  const imageRef = useRef(null);
  const videoRef = useRef(null);
  const goToNextSlideArrow = useRef();
  const goToPreviousSlideArrow = useRef();
  const checklistItemsScrollContainer = useRef(false);
  const checklistItemsScrollUp = useRef(false);
  const checklistItemsScrollDown = useRef(false);

  const isVideo = plane.media?.dicom_media_type === "video";
  const [isFullScreen, setIsFullScreen] = useState(isVideo || trimester === 'ND' || window.localStorage.getItem("slide-full-screen") === "true");
  const [lastSelectedAnomaly, setLastSelectedAnomaly] = useState(false);
  const [predictionSource, setPredictionSource] = useState(false);
  const [predictionScore, setPredictionScore] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [qualityCriteriaList, setQualityCriteriaList] = useState([]);
  const [anomaliesToDisplay, setAnomaliesToDisplay] = useState([]);
  const [viewEvaluation, setViewEvaluation] = useState(null);
  const [predictedQualityCriteria, setPredictedQualityCriteria] = useState([]);
  const [alternativeSlides, setAlternativeSlides] = useState([]);
  const [watchVideo, setWatchVideo] = useState(false);
  const [enteredFromMedia, setEnteredFromMedia] = useState(false);

  const instancePrediction = (plane?.media
    ?.predictions
    ?.sort((a, b) => b.id - a.id)
    .find(prediction => prediction.type === 'view' && prediction.status === 'done')?.data || [])
    ?.[0];

  const annotation_detected = instancePrediction?.annotation_detected

  const unmatched = plane.media && !plane.media?.verified && !plane.slide?.optional && !plane.slide?.verified && !plane.slide?.is_video;
  const isOtherNotVerified = useMemo(() => plane?.slide?.type === "other" && plane.media?.verified === false, [plane.slide?.type, plane.media?.verified]);
  const invalidQualityCriteriaArePresent = useMemo(() => !!predictedQualityCriteria?.quality_criterias?.find(qc => !qc.is_valid), [predictedQualityCriteria]);
  const isFavorite = plane?.media?.selected_for_print ?? examinationContext.instancesToPrint.includes(plane?.media?.id);
  const isExtractedFromVideo = !!plane.media?.dicom_origin_id;
  const isNotDoppler = plane.slide?.techno !== "doppler";

  const [categoryLabel, setCategoryLabel] = useState('');
  useEffect(() => {
    setCategoryLabel(appContext.getDefaultCategoryLabelForSlide(plane.slide, trimester, __))
  }, [plane])

  const frameRate = parseInt(plane?.media?.frame_rate) ?? config.defaultVideoFrameRate;
  const currentFrame = plane.media?.sop_instance_uid?.split("_")?.slice(-1)[0];

  const updateIsFullScreen = (value) => {
    window.localStorage.setItem("slide-full-screen", value);
    setIsFullScreen(value);
  }

  /* refresh after having edited */
  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.src = imageRef.current?.src;
    }
  }, [JSON.stringify(plane?.media?.user_edits)]);
  
  const nextSlideKey = useMemo(() => {
    const currentSlideIndex = filteredSlides.findIndex(s => s.key === plane.slide?.key);
    const nextSlides = [...filteredSlides.slice(currentSlideIndex + 1), ...filteredSlides.slice(0, currentSlideIndex)];
    return nextSlides.find(s => enteredFromMedia ? s.mediaId : s.id)?.key; // next media if current slide contain a media, otherwise next slide
  }, [filteredSlides, plane.slide?.key, enteredFromMedia]);

  const prevSlideKey = useMemo(() => {
    const currentSlideIndex = filteredSlides.findIndex(s => s.key === plane.slide?.key);
    const previousSlides = [...filteredSlides.slice(currentSlideIndex + 1), ...filteredSlides.slice(0, currentSlideIndex)].reverse();
    return previousSlides.find(s => enteredFromMedia ? s.mediaId : s.id)?.key; // next media if current slide contain a media, otherwise next slide
  }, [filteredSlides, plane.slide?.key, enteredFromMedia]);

  const changeAssociation = useCallback((slide) => {
    examinationContext.setInstanceAssociation(slide, plane.media.id);
    setLastMatchedSlide(slide);
  }, [plane.media?.id, examinationContext.examination.id])

  // TODO: move this to examination context.
  const getAssociations = useCallback(async () => {
    if (examinationContext.examination.id) {
      const { data: { data: associations } } = await ResourceApi.getAssocInstanceByExamId(examinationContext.examination.id);
      const newAssociations = {}
      for (const association of associations) {
        newAssociations[association.dicom_instance_id] = association;
      }
      return newAssociations
    }
  }, [examinationContext.examination.id])

  const onPrintClick = useCallback(async () => {
    if (!plane.media?.id) return false;
    examinationContext.toggleInstanceIsFavorite(plane.media.id, !isFavorite);
  }, [plane.media?.id, examinationContext.toggleInstanceIsFavorite, isFavorite]);

  const goToVideo = () => {
    if (plane.media?.dicom_origin_id) {
      setSelectedPlaneByMediaId(plane.media?.dicom_origin_id);
    }
  }

  const goToPreviousSlide = useCallback(() => {
    if (prevSlideKey) {
      setSelectedPlaneByKey(prevSlideKey);
    }
  }, [prevSlideKey]);

  const goToNextSlide = useCallback(() => {
    if (nextSlideKey) {
      setSelectedPlaneByKey(nextSlideKey);
    }
  }, [nextSlideKey]);

  const onKeyDown = useCallback((e) => {
    switch (e.code) {
      case "ArrowRight":
      case "ArrowDown":
        goToNextSlideArrow?.current?.click();
        break;
      case "ArrowLeft":
      case "ArrowUp":
        goToPreviousSlideArrow?.current?.click();
        break;
      case "Escape":
        !windowContext.windowExists("slide") && onClose();
        break;
    }
  }, [goToNextSlide, goToPreviousSlide, onClose, windowContext.windowExists("slide")]);

  const onChecklistScroll = useCallback(() => {
    const isScrollable = checklistItemsScrollContainer.current?.scrollHeight > checklistItemsScrollContainer.current?.offsetHeight;
    const isScrollableUp = isScrollable && checklistItemsScrollContainer.current?.scrollTop > 0;
    const isScrollableDown = isScrollable && checklistItemsScrollContainer.current?.scrollTop + checklistItemsScrollContainer.current?.offsetHeight < checklistItemsScrollContainer.current?.scrollHeight;
    checklistItemsScrollUp.current?.classList?.toggle("visible", isScrollableUp);
    checklistItemsScrollDown.current?.classList?.toggle("visible", isScrollableDown);
  }, [checklistItemsScrollContainer.current, checklistItemsScrollUp.current, checklistItemsScrollDown.current]);

  const onQualityCriteriaClick = async (criteria) => {
    if (!plane.slide?.id || !plane.media?.id) return null
    const newQualityCriterias = predictedQualityCriteria?.quality_criterias
      .map(q => q.id === criteria.id
        ? { ...q, is_valid: !(q.is_valid ?? true), source: "user" }
        : q
      );
    await ResourceApi.updateQualityCriteria(
      examinationContext.examination.id,
      plane.media?.id,
      plane.slide.id,
      newQualityCriterias.filter(item => item.source === "user")
    )
  }

  const onViewEvaluationClick = async () => {
    if (plane.media?.id && plane.slide?.id) {
      await ResourceApi.updateViewEvaluation(examinationContext.examination.id, plane.media.id, plane.slide.id, !viewEvaluation?.warning)
    }
  }

  const onViewEvaluationReverseClick = async () => {
    if (plane.media?.id && plane.slide?.id) {
      await ResourceApi.updateViewEvaluation(examinationContext.examination.id, plane.media.id, plane.slide.id, null)
    }
  }

  const onAllQualityCriteriaClick = async (currentValue) => {
    if (!plane.slide?.id || !plane.media?.id) return null
    const newQualityCriterias = predictedQualityCriteria?.quality_criterias
      .map(q => q.is_valid !== currentValue
        ? { ...q, is_valid: currentValue, source: "user" }
        : q
      );
    await ResourceApi.updateQualityCriteria(
      examinationContext.examination.id,
      plane.media?.id,
      plane.slide.id,
      newQualityCriterias.filter(item => item.source === "user")
    )
  }

  const onAllQualityCriteriaReverseClick = async () => {
    if (!plane.slide?.id || !plane.media?.id) return null
    await ResourceApi.updateQualityCriteria(
      examinationContext.examination.id,
      plane.media?.id,
      plane.slide.id,
      []
    )
  }

  const onAnomalyClick = async (anomaly) => {
    if (!plane.slide?.id || !plane.media?.id) return null

    const newAnomalies = anomaliesToDisplay
      ?.map(a => a.id === anomaly.id
        ? { ...a, status: "no", source: "user" }
        : a
      );
    await ResourceApi.updateAnomaly(
      examinationContext.examination.id,
      plane.media?.id,
      plane.slide.id,
      newAnomalies.filter(item => item.source === "user")
    )
  }

  useEffect(() => {
    setEnteredFromMedia(!!plane.media);
  }, []);

  useEffect(() => {
    setIsFullScreen(isVideo || trimester === 'ND' || window.localStorage.getItem("slide-full-screen") === "true");
  }, [trimester, isVideo]);

  useEffect(() => {
    setQualityCriteriaList(() => !plane.slide
      ? []
      : examinationContext.QUALITYCRITERIA
        .filter(q => q.instance_views.some(assoc_instance_view => assoc_instance_view.trimester === trimester && plane.slide?.id === assoc_instance_view.instance_view_id))
    );
  }, [examinationContext.QUALITYCRITERIA, trimester, plane.slide?.id]);

  useEffect(() => {
    const base = examinationContext.getQualityCriteriaByInstanceViewId(plane.slide, plane.media);
    let newPredictedQualityCriteria = { score: plane.media?.qc_prediction?.data?.[0]?.score }
    newPredictedQualityCriteria["quality_criterias"] = [
      ...base
        ?.filter(qc => qc.is_valid === false)
        .sort((a, b) => {
          return a.warning_level === "mandatory" ? -1 : 1 || Number(b.detectable) - Number(a.detectable);
        }),
      ...base
        ?.filter(qc => qc.is_valid === true)
        .sort((a, b) => {
          return a.warning_level === "mandatory" ? -1 : 1 || Number(b.detectable) - Number(a.detectable);
        }),
      ...base
        ?.filter(qc => isNullOrUndefined(qc.is_valid)),
    ];

    setPredictedQualityCriteria(newPredictedQualityCriteria);

    setAnomaliesToDisplay(plane.media?.anomaly_prediction?.data?.map(anomaly => {
      const anomaliesOverrides = plane.media?.anomaly_override || [];
      const anomalyOverride = anomaliesOverrides.find(a => a.id === anomaly.id);
      return anomalyOverride
        ? { ...anomaly, status: anomalyOverride.status, source: "user" }
        : { ...anomaly }
    }) || []);

    setViewEvaluation(
      () => {
        const score = plane.media?.ve_prediction?.data?.[0]?.conf
        const viewEvaluationIsOverriden = !isNullOrUndefined(plane.media?.view_evaluation_override)
        const warningFromOverride = viewEvaluationIsOverriden && plane.media?.view_evaluation_override === true
        const warningFromScore = plane.media?.ve_prediction?.data?.[0]?.conf.normalized_score < config.evaluationScoreThreshold

        return {
          score: score,
          warning: viewEvaluationIsOverriden ? warningFromOverride : warningFromScore,
          source: viewEvaluationIsOverriden ? "user" : "vision-ai"
        }
      }
    )
  }, [JSON.stringify(plane.media), qualityCriteriaList]);

  const unusualQualityCriteriaCount = useMemo(() => predictedQualityCriteria?.quality_criterias?.filter(qc => qc.is_valid === false && qc.warning_level === "mandatory").length, [JSON.stringify(predictedQualityCriteria?.quality_criterias)]);

  useEffect(() => {
    const predictions = examinationContext.getPredictions(plane.media, isOtherNotVerified ? false : plane.slide, 3);
    setAlternativeSlides([
      ...predictions.filter(p => p?.type && p.type !== "other"),
      slides.find(slide => slide?.type === "other")
    ].map(prediction => slides.find(slide => prediction?.id === slide.id)));
  }, [JSON.stringify(plane.media), JSON.stringify(plane.slide), slides, isOtherNotVerified])

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    }
  }, [plane.slide?.id]);

  useEffect(() => {
    setLastSelectedAnomaly(anomalies.map(anomalyId => checklistItems.find(item => item.id === anomalyId)).filter(anomaly => anomaly).pop());
    setConfirmDelete(false);
  }, [anomalies, plane.media?.id]);

  useEffect(() => {
    setPredictionSource(plane.media?.source);
    setPredictionScore({
      score: predictedQualityCriteria?.score?.normalized_score,
      min: predictedQualityCriteria?.quality_criterias,
    });
    setConfirmDelete(false);
  }, [JSON.stringify(plane.media), predictedQualityCriteria]);

  useEffect(() => {
    getAssociations()
  }, [getAssociations]);

  useEffect(() => {
    onChecklistScroll();
  }, [onChecklistScroll]);

  const getSlideOptions = useCallback(() => {
    return [
      {
        label: __("examination-plane.reannotate"),
        icon: "change-slide",
        value: "reannotate",
        disabled: !plane.media,
        keep: !isVideo && trimester !== 'ND',
      },
      {
        label: __("examination-plane.resetEvalaution"),
        icon: "ai-detection",
        value: "reset-evaluation",
        disabled: !plane.media,
        keep: !isVideo && plane.media?.verified && isFeatureFlagEnabled("sonio.detect"),
      },
      {
        label: plane.media?.to_retake ? __("examination-plane.doNotAskForRetake") : __("examination-plane.askForRetake"),
        icon: "reload",
        value: "retake",
        disabled: !plane.media,
        keep: !isVideo && plane.media?.verified,
      },
      {
        label: __(`examination-plane.edit.image`),
        icon: "edit",
        value: "edit",
        disabled: !plane.media || !examinationContext.canEdit,
        keep: !isVideo && isFeatureFlagEnabled("sonio.image_edit"),
      },
      {
        label: __(`examination-plane.delete.${plane.media?.dicom_media_type}`),
        icon: "trash",
        value: "delete",
        disabled: !plane.media,
        keep: true,
      },
    ].filter(({keep}) => keep)
  }, [plane.media, isVideo]);

  const checklist_item_for_view = examinationContext.examination.checklist_items?.filter(checklist_item => checklist_item.instance_view_id.includes(plane.slide?.id));
  const fetusSex = examinationContext.fetusSexVisibility === "hidden" ? false : examinationContext.examination?.medical_history?.['medicalexam.fetus.sex']?.value;

  return (
    <div className={`slide-panel-container trimester-${trimester} ${plane.media?.id ? 'has-media' : ''} ${isVideo ? 'is-video' : ''} ${isFullScreen ? 'is-fullscreen' : ''}`}>
      <div className="slide-panel-header">
        <div className="slide-panel-slide">
          {!isVideo && trimester !== "ND" && (
            <div className={`slide-panel-slide-container ${!!plane.media ? 'active' : 'inactive'} ${plane.media?.verified === false ? 'smart-match' : ''}`}>
              <div className="slide-panel-slide-meta">
                {unmatched && (
                  <div className="slide-panel-slide-slide">
                    {plane.slide
                      ?
                      <SlideDrawing
                        version="2"
                        trimester={trimester}
                        slide={plane.slide}
                        type={plane.slide.type}
                        rotation={plane.slide.transformation.find(transformationDef => transformationDef.fetus_position_id === examinationContext.examination.fetus_position_id)}
                        preventAnimations={true}
                        sex={fetusSex}
                      />
                      :
                      <div className="slide-panel-slide-feedback">
                        <LoaderInline />&nbsp;
                        {__("examination-plane.pending")}
                      </div>
                    }
                  </div>
                )}
                <div className="slide-panel-slide-name">
                  {plane.slide && (!plane.media || unmatched) && (
                    <div>
                      <div>
                        {annotation_detected ? (
                          <span className="original-annotation">{annotation_detected[0].toUpperCase() + annotation_detected.substr(1)}</span>
                        ) : (
                          <span className="original-annotation">{plane.slide?.label?.[currentLanguage]}</span>
                        )}
                        {!!plane.slide?.view_count && plane.slide?.view_count > 1 && (
                          <span className="slide-counter">
                            <b>{plane.slide?.idx_in_view + 1}</b>/{plane.slide?.view_count}
                          </span>
                        )}
                        {!!categoryLabel && <span className="slide-panel-slide-category">{categoryLabel}</span>}
                        {plane.media?.to_retake && (
                          <span className="slide-panel-slide-to-retake"><Icon name="bell" /> {__("examination-plane.toBeRetaken")}</span>
                        )}
                      </div>
                      {!!plane.media && !isNullOrUndefined(viewEvaluation?.score?.normalized_score) && (
                        <span className="slide-panel-detection-score">
                          <DetectionScore
                            score={viewEvaluation?.score?.normalized_score}
                            onDismiss={onViewEvaluationClick}
                            view={plane.slide}
                            viewName={plane.slide?.label[currentLanguage]}
                            source={viewEvaluation?.source}
                            alternativeSlides={alternativeSlides}
                            changeAssociation={changeAssociation}
                          />
                          {annotation_detected && (
                            <span className="template-annotation">{plane.slide?.label?.[currentLanguage]}</span>
                          )}
                        </span>
                      )}
                    </div>
                  )}
                  {unmatched && (
                    <div className="slide-panel-slide-confirm-matching">
                      <h2>{__("examination-plane.smartMatching.title")}</h2>
                    </div>
                  )}
                </div>
                <div className="slide-panel-slide-score">
                  {!!plane.media && !!predictionSource && predictionScore === "error" && (
                    <div>
                      <Icon name="info" />
                      &nbsp;
                      <span>{__("examination-plane.error")}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="slide-panel-slide-cta">
          <div className="slide-panel-options">
            {!!plane.media && (
              <>
                {!isVideo && (<div className="slide-panel-print">
                  <FavoriteButton
                    isFavorite={isFavorite}
                    onClick={onPrintClick}
                  />
                </div>)}
                <SelectInput
                  placeholder={{ icon: "more", label: "" }}
                  showDropDown={false}
                  optionsFontSize={"large"}
                  options={getSlideOptions()}
                  onChange={async (value) => {
                    switch(value) {
                      case "delete":
                        return setConfirmDelete(true);
                      case "reannotate":
                        if(!plane.media) return;
                        return setShowSlideBrowser({ slide: plane.slide, media: plane.media });
                      case "reset-evaluation":
                        if(!plane.media) return;
                        onViewEvaluationReverseClick();
                        onAllQualityCriteriaReverseClick();
                        return;
                      case "retake":
                        if(!plane.media) return;
                        if(!plane.slide?.id) return;
                        if (plane.media?.to_retake) {
                          return await ResourceApi.updateToRetake(examinationContext.examination.id, plane.media.id, plane.slide.id, false)
                        } else {
                          return await ResourceApi.updateToRetake(examinationContext.examination.id, plane.media.id, plane.slide.id, true)
                        }
                      case "edit":
                        if(!plane.media) return;
                        if (windowContext.isDetached) {
                          // workaround for bug happening when editing an image from a detached window in app-mode
                          return history.push(`/window/dicom-editor/${examinationContext.examination.id}/${plane.media.id}`);
                        } else {
                          return history.push(`/dicom-instance-edit/${examinationContext.examination.id}/${plane.media.id}`);
                        }
                    }
                  }}
                />
              </>
            )}
          </div >
        </div >
      </div >
      <div className="slide-panel-body">
        {!isVideo ? (
          <>
            <div className="slide-panel-main">
              <div className="slide-panel-image">
                <div className="slide-panel-image-inner">
                  {!plane.media && (
                    <div className="slide-panel-waiting">
                      <b>
                      {plane.slide?.label?.[currentLanguage]}
                        {!!plane.slide?.view_count && plane.slide?.view_count > 1 && (
                          <> {plane.slide?.idx_in_view + 1}/{plane.slide?.view_count}</>
                        )}
                      </b>
                      {__("examination.missingPlane")}
                    </div>
                  )}
                  {trimester !== 'ND' && (
                    <div className="slide-panel-switchfullscreen" onClick={(e) => updateIsFullScreen(!isFullScreen)}></div>
                  )}
                  {!!plane.media && (
                    !watchVideo ? (
                      <img
                        src={getInstanceThumbnailUri(plane.media.id)}
                        alt=""
                        ref={imageRef}
                      />
                    ) : (
                      <div className="slide-panel-video inline">
                        <video src={getInstanceVideoUri(plane.media?.dicom_origin_id)} loop ref={videoRef} onLoadedData={() => {
                          if (!!currentFrame) videoRef.current.currentTime = currentFrame / frameRate;
                          videoRef?.current?.play();
                        }} />
                      </div>
                    )
                  )}
                </div>
                {isExtractedFromVideo && (
                  <div className="slide-panel-extracted-from-video">
                    <Icon name="video" /> <Icon name="ai-detection" /> {__("examination.extractedFromVideo")}
                    <br />
                    <Button
                      label={watchVideo ? __("examination.backToImage") : __("examination.watchVideo")}
                      icon={watchVideo ? "image" : "play"}
                      size="small"
                      variant="outline"
                      onClick={() => {
                        setWatchVideo(prev => !prev);
                      }}
                    />&nbsp;
                    <Button
                      label={__("examination.goToVideo")}
                      icon="video"
                      size="small"
                      variant="outline"
                      onClick={() => {
                        goToVideo();
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {unmatched ? (
              <div className="slide-panel-alternatives">
                <div className="slide-panel-alternatives-inner">
                  <SmartMatch
                    media={plane.media}
                    alternativeSlides={alternativeSlides}
                    trimester={trimester}
                    changeAssociation={changeAssociation}
                    setShowSlideBrowser={setShowSlideBrowser}
                  />
                </div>
              </div>
            ) : (
              <div className="slide-panel-sidebar">
                <div className="slide-panel-sidebar-inner">
                  <div className="slide-panel-checklist">
                    {isFeatureFlagEnabled("sonio.checklist") && <div className="slide-panel-searchbar">
                      <GlobalSearchBar fullwidth="true" callback={(data) => {
                        if (data.type === "checklistitem") {
                          const slide = slides.find(slide => data.item.instance_view_id.includes(slide.id));
                          if (slide) {
                            setSelectedPlaneByKey(slide.key);
                          }
                        }
                      }} />
                    </div>}

                    {!!checklist_item_for_view?.length &&
                      <>
                        <div className="slide-panel-sidebar-title">
                          <h2 className="section-title">
                            {__("examination-plane.checklistItems")}
                          </h2>
                        </div>
                        {(!plane.media || plane.media?.verified !== false) && (
                          <>
                            <div className="slide-panel-checklist-items">
                              <ul ref={checklistItemsScrollContainer} onScroll={onChecklistScroll}>
                                {checklist_item_for_view
                                  .map(checklistItem => {
                                    return !isNullOrUndefined(checklistItem) && (
                                      <li
                                        key={`ci_${checklistItem.id}`}
                                        className={'is-not-verified warning-mandatory'}
                                        onClick={() => ResourceApi.updateChecklistItemStatus(examinationContext.examination.id, checklistItem.id, "usual", checklistItem.examination_fetus_id)}>
                                        <span className="exam-item-name">
                                          <span>
                                            <div className="exam-item-context">
                                              <span>{
                                                checklistItem.parents
                                                  .map(parentChecklistItem => parentChecklistItem.label[currentLanguage])
                                                  .join(" > ")
                                              }</span>
                                            </div>
                                            {formatCheckListItemLabel(checklistItem, currentLanguage, __, examinationContext.examination.fetuses)}
                                          </span>
                                          <Icon name="close" />
                                        </span>
                                      </li>
                                    )
                                  }
                                  )}
                              </ul>
                            </div>
                            {isFeatureFlagEnabled("sonio.dx_v2") && lastSelectedAnomaly && (
                              <div className="slide-panel-gotodx">
                                <Button
                                  label={__("examination.startDx")}
                                  size="full-width"
                                  color="ocra"
                                  iconAfter="right"
                                  onClick={() => {
                                    dxContext.setCurrentChecklistItem(lastSelectedAnomaly);
                                    return history.push(`/exam/${examinationContext.examination.id}/dx`);
                                  }}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    }
                  </div>
                  <div className="slide-panel-qc">
                    <div className="slide-panel-sidebar-title">
                      <h2 className="section-title">
                        {__("examination-plane.qualityCriteria")}
                        {!!unusualQualityCriteriaCount && (
                          <span className="unusual-quality-criteria-count">{unusualQualityCriteriaCount}</span>
                        )}
                      </h2>
                      {!!plane.media && (
                        <Button
                          variant="link"
                          icon="done-all"
                          disabled={!invalidQualityCriteriaArePresent}
                          label={__("examination-plane.qualityCriteria.dismiss")}
                          onClick={() => onAllQualityCriteriaClick(invalidQualityCriteriaArePresent)}
                        />
                      )}
                    </div>
                    {(!plane.media || plane.media?.verified !== false) && (
                      <>
                        <div className="slide-panel-checklist-items">
                          <div className="slide-panel-checklist-items-scroll-up" ref={checklistItemsScrollUp} />
                          <div className="slide-panel-checklist-items-scroll-down" ref={checklistItemsScrollDown} />
                          <ul ref={checklistItemsScrollContainer} onScroll={onChecklistScroll}>
                            {/* anomaliesToDisplay?.filter(anomaly => anomaly.status !== "no").map(anomaly => (
                              <li key={`anomaly_${anomaly.id}`} className="anomaly checked" onClick={() => { onAnomalyClick(anomaly) }}>
                                <span className="exam-item-name">
                                  <span>{examinationContext.getMalformationById(anomaly.id)?.description?.[currentLanguage] ?? examinationContext.getMalformationById(anomaly.id)?.canonical_name?.[currentLanguage]}</span>
                                  <span className="item-progress">
                                    <div className="progress"><span>{__("examination-plane.suspected")}</span></div>
                                  </span>
                                </span>
                              </li>
                            )) */}
                            {predictedQualityCriteria?.quality_criterias?.map(criteria => {
                              return !isNullOrUndefined(criteria) && isNotDoppler && (
                                <li
                                  key={`qc_${criteria.id}`}
                                  className={`${isNullOrUndefined(criteria.is_valid) ? '' : (criteria.is_valid ? '' : 'is-not-verified')} warning-${criteria.warning_level} ${criteria?.detectable ? 'is-detectable' : ''} ${criteria?.is_detected ? 'is-detected' : 'is-not-detected'} ${criteria?.detectable && !!criteria.source ? "checked" : ""}`}
                                  onClick={() => onQualityCriteriaClick(criteria)}>
                                  <span className="exam-item-name">
                                    <span>{criteria.label[currentLanguage]}</span>
                                    {!isNullOrUndefined(criteria?.is_valid) && (
                                      <span className={`item-progress ${!criteria.is_valid ? 'highlight' : ''}`}>
                                        <Icon name={criteria.source === "user" ? "manual" : "ai-detection"} />
                                        {criteria?.is_detected && (
                                          <div className="progress">
                                            {!criteria.is_valid && (
                                              __("examination-plane.qualityCriteria.notValid")
                                            )}
                                            {criteria.is_valid && (
                                              __("examination-plane.qualityCriteria.valid")
                                            )}
                                          </div>
                                        )}
                                      </span>
                                    )}
                                  </span>
                                </li>
                              )
                            }
                            )}
                          </ul>
                        </div>
                        {isFeatureFlagEnabled("sonio.dx_v2") && lastSelectedAnomaly && (
                          <div className="slide-panel-gotodx">
                            <Button
                              label={__("examination.startDx")}
                              size="full-width"
                              color="ocra"
                              iconAfter="right"
                              onClick={() => {
                                dxContext.setCurrentChecklistItem(lastSelectedAnomaly);
                                return history.push(`/exam/${examinationContext.examination.id}/dx`);
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <SlidePanelVideo
            plane={plane}
            filteredSlides={filteredSlides}
            setIsFullScreen={updateIsFullScreen}
            extractedFrames={extractedFrames}
            setSelectedPlaneByMediaId={setSelectedPlaneByMediaId}
          />
        )}
      </div>
      <div className="slide-panel-dt-pagination">
        {prevSlideKey ? (
          <div onClick={goToPreviousSlide} ref={goToPreviousSlideArrow} className="previous"><Icon name="left" /></div>
        ) : (
          <div onClick={onClose} ref={goToPreviousSlideArrow} className="previous"></div>
        )}
        {nextSlideKey ? (
          <div onClick={goToNextSlide} ref={goToNextSlideArrow} className="next"><Icon name="right" /></div>
        ) : (
          <div onClick={onClose} ref={goToNextSlideArrow} className="next"></div>
        )}
      </div>
      {
        confirmDelete && (
          <Popup
            message={__("examination-plane.delete.confirmMessage")}
            cta={<>
              <Button
                label={__("examination-plane.delete.cancel")}
                variant="outline"
                onClick={() => setConfirmDelete(false)}
              />&nbsp;
              <Button
                label={__("examination-plane.delete.confirm")}
                onClick={() => examinationContext.deleteInstance(plane.media.id)}
              />
            </>}
            onBackgroundClick={() => setConfirmDelete(false)}
          />
        )
      }
    </div >
  )
}

export default withTranslation()(SlidePanel);
