import { isValidElement, cloneElement } from "react";
import { renderToStaticMarkup } from 'react-dom/server';

export default function ReportTemplateBlock({defaultKey='', children, props, fetus = 1, numberOfFetuses, fetusNames}) {
  const {layout, width = null, align = null, color = null, background = null, size = "", outlined = null, "avoid-break": avoidBreak, "auto-hide": autoHide} = (props ?? {});

  const style = {
    minWidth: width ?? "auto",
    maxWidth: width ? `min(${width}, 100%)` : "100%",
  };

  if (align) style.textAlign = align;
  if (color) style.color = color;
  if (background) style.backgroundColor = background;

  const sizes = {
    xl: 1,
    l: 2,
    m: 3,
    s: 4,
    xs: 5,
    xxs: 6,
  };

  const childrenWithFetusNumber = Array.isArray(children) ? children.map((child, i) => {
    if (isValidElement(child)) {
      return cloneElement(child, { ...props, numberOfFetuses, fetus, fetusNames, defaultKey: `${defaultKey || ""}_${i}_block_${fetus}` });
    }
    return child;
  }) : children;

  const notPrintable = autoHide && !renderToStaticMarkup(childrenWithFetusNumber).includes('has-printable-value');

  return (
    <div className={`block size-${sizes[size] || "default"} ${outlined ? 'is-outlined' : ''} ${avoidBreak ? 'avoid-break' : ''} ${(notPrintable) ? 'not-printable' : ''} ${background ? 'has-background' : ''}`} style={style} data-layout={layout}>{childrenWithFetusNumber}</div>
  );
}
