import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import Button from "../../../atoms/Button/Button";
import ButtonGroup from "../../../atoms/ButtonGroup/ButtonGroup";
import Icon from "../../../atoms/Icon/Icon";
import config from "../../../config";
import { ExaminationContext } from "../../../context-providers/Examination";
import ResourceApi from "../../../services/resource";
import { getInstanceThumbnailUri, getInstanceVideoUri } from "../../../utils";
import "./SlidePanelVideo.css";


const NOT_STARTED = "not_started"
const IN_PROGRESS = "in_progress"
const DONE = "done"
const ERROR = "error"
const UNKOWN = "error"

const SlidePanelVideo = ({
  t: __,
  plane,
  setIsFullScreen,
  extractedFrames,
  setSelectedPlaneByMediaId,
}) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const videoRef = useRef(null);
  const videoProgressRef = useRef(null);
  const videoProgressBarRef = useRef(null);
  const canvasRef = useRef(null);
  const canvasContextRef = useRef(null);
  const requestRef = useRef(null);

  const [showExtractedFrames, setShowExtractedFrames] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lastVideoActionIcon, setLastVideoActionIcon] = useState("empty");
  const [canBeCaptured, setCanBeCaptured] = useState(false);

  const examinationContext = useContext(ExaminationContext);

  const trackEvent = (event_type, event_data) => {
    ResourceApi.createBIEvent({
      ...event_data,
      event_type: event_type,
      examination_id: examinationContext.examination.id,
      instance_id: plane.media.id
    });
  }

  useEffect(() => {
    trackEvent("video_plane_viewed", {});
  }, [plane?.media?.id]);

  const frameRate = parseInt(plane?.media?.frame_rate) ?? config.defaultVideoFrameRate;
  const frameTime = 1 / frameRate;

  const isVideoProcessingPending = useMemo(() => plane.media.video_processing_state === NOT_STARTED, [plane.media.video_processing_state]);

  const isVideoProcessingDone = plane.media.video_processing_state === DONE;

  const isVideoProcessingInProgress = plane.media.video_processing_state === IN_PROGRESS;

  const isVideoProcessingDiscarded = plane.media.video_processing_state === ERROR;

  const videoProcessingFinalState = useMemo(() => isVideoProcessingDone
    ? DONE
    : isVideoProcessingInProgress
      ? IN_PROGRESS
      : isVideoProcessingDiscarded
        ? ERROR
        : isVideoProcessingPending
          ? NOT_STARTED
          : UNKOWN, [
    isVideoProcessingDone,
    isVideoProcessingInProgress,
    isVideoProcessingDiscarded,
    isVideoProcessingPending,
  ]);

  const isVideoExtractionPending = useMemo(() => plane.media.video_extraction_state === NOT_STARTED, [plane.media.video_extraction_state]);

  const isVideoExtractionDone = plane.media.video_extraction_state === DONE;

  const isVideoExtractionInProgress = plane.media.video_extraction_state === IN_PROGRESS;

  const isVideoExtractionDiscarded = plane.media.video_extraction_state === ERROR;

  const videoExtractionFinalState = useMemo(() => isVideoExtractionDone
    ? DONE
    : isVideoExtractionInProgress
      ? IN_PROGRESS
      : isVideoExtractionDiscarded
        ? ERROR
        : isVideoExtractionPending
          ? NOT_STARTED
          : UNKOWN
    , [
      isVideoExtractionDone,
      isVideoExtractionInProgress,
      isVideoExtractionDiscarded,
      isVideoExtractionPending,
    ]);

  const capture = useCallback(() => {
    if (!videoRef.current || !canvasRef.current || !canvasContextRef.current) {
      return;
    }
    resetCanvasSize();
    canvasContextRef.current.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
    requestRef.current = requestAnimationFrame(() => {
      capture();
    });
  }, [videoRef?.current]);

  const onVideoPlaying = () => {
    if (videoRef.current) {
      const progress = 100 / videoRef.current.duration * videoRef.current.currentTime;
      if (videoProgressBarRef.current) videoProgressBarRef.current.style.width = progress + '%';
      setCanBeCaptured(!extractedFrames.some(frame => getCurrentFrameId() === Number(frame?.sop_instance_uid?.split("_")?.slice(-1)[0])));
      if (!isNaN(videoRef.current.duration) && !showExtractedFrames) {
        setShowExtractedFrames(true);
      }
    }
  }

  const onClickVideoProgressBar = (e) => {
    if (!videoRef?.current) return false;
    if (!videoRef.current.paused) pauseVideo("progress-bar");
    const barCoords = e.currentTarget?.getBoundingClientRect();
    const timePosition = videoRef.current.duration / barCoords.width * (e.clientX - barCoords.left);
    videoRef.current.currentTime = Math.round(timePosition * frameRate) / frameRate;
  }

  const onDragStartVideoProgressBar = (e) => {
    e.preventDefault();
    document.body.addEventListener('mousemove', onDragVideoProgressBar);
    document.body.addEventListener('touchmove', onDragVideoProgressBar);
    document.body.addEventListener('mouseup', onDropVideoProgressBar);
    document.body.addEventListener('touchend', onDropVideoProgressBar);
  };

  const onDragVideoProgressBar = (e) => {
    e.preventDefault();
    if (!videoRef?.current) return false;
    if (!videoRef.current.paused) pauseVideo("progress-bar");
    const barCoords = videoProgressRef.current.getBoundingClientRect();
    const timePosition = videoRef.current.duration / barCoords.width * (e.clientX - barCoords.left);
    videoRef.current.currentTime = Math.round(timePosition * frameRate) / frameRate;
  }

  const onDropVideoProgressBar = (e) => {
    e.preventDefault();
    document.body.removeEventListener('mousemove', onDragVideoProgressBar);
    document.body.removeEventListener('touchmove', onDragVideoProgressBar);
    document.body.removeEventListener('mouseup', onDropVideoProgressBar);
    document.body.removeEventListener('touchend', onDropVideoProgressBar);
  };

  const getCurrentFrameId = () => {
    return Math.round(videoRef?.current?.currentTime * frameRate);
  }

  const sendDicomWithImage = useCallback(async () => {
    setCanBeCaptured(false);

    const imgThumbnail = canvasRef.current.toDataURL().split(',')[1];

    await ResourceApi.ingestDicomFromVideo({
      "frameId": getCurrentFrameId(),
      "videoDicomId": plane.media.id,
      "thumbnailBase64": imgThumbnail
    });

    trackEvent("video_frame_extracted", {
      frame_id: getCurrentFrameId(),
    });
  }, [ResourceApi.ingestDicomFromVideo, plane?.media?.id, frameRate])

  const processVideo = useCallback(async () => {
    await ResourceApi.processVideo(plane?.media?.id)
  }, [ResourceApi.processVideo, plane?.media?.id])

  useEffect(() => {
    setShowExtractedFrames(false);
  }, [plane?.media?.id])

  useEffect(() => {
    videoRef?.current?.addEventListener('timeupdate', onVideoPlaying);
    return () => {
      setShowExtractedFrames(false);
    }
  }, [videoRef.current])

  useEffect(() => {
    setIsPlaying(!videoRef?.current?.paused);
  }, [videoRef?.current?.paused]);

  useEffect(() => {
    if (canvasRef.current?.getContext) {
      canvasContextRef.current = canvasRef.current.getContext('2d');
      canvasContextRef.current.clearRect(0, 0, videoRef.current.videoWidth, videoRef.current.videoHeight);
    }
  }, [videoRef.current?.videoWidth, videoRef.current?.videoHeight, canvasRef.current?.getContext]);

  useEffect(() => {
    requestAnimationFrame(capture);

    return () => {
      cancelAnimationFrame(requestRef.current);
    }
  }, [capture]);

  const resetCanvasSize = () => {
    canvasRef.current.width = videoRef.current?.videoWidth;
    canvasRef.current.height = videoRef.current?.videoHeight;
  }

  const previousFrame = useCallback((origin) => {
    trackEvent("video_frame_by_frame", { direction: "backward", origin: origin })
    videoRef.current.pause();
    videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - frameTime);
    setLastVideoActionIcon("left");
  }, [videoRef.current]);

  const nextFrame = useCallback((origin) => {
    trackEvent("video_frame_by_frame", { direction: "forward", origin: origin })
    videoRef.current.pause();
    videoRef.current.currentTime = Math.min(videoRef.current?.duration, videoRef.current.currentTime + frameTime);
    setLastVideoActionIcon("right");
  }, [videoRef.current]);

  const pauseVideo = useCallback((origin) => {
    trackEvent("video_play_pause", {
      action: videoRef.current.paused ? "play" : "pause",
      origin: origin
    })
    if (videoRef.current.paused) {
      videoRef.current.play();
      setLastVideoActionIcon("play");
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setLastVideoActionIcon("pause");
      setIsPlaying(false);
    }
  }, [videoRef.current?.paused]);

  const onVideoLoaded = () => {
    setShowExtractedFrames(true);
    resetCanvasSize();
  }

  useEffect(() => {
    if (videoProgressBarRef.current) videoProgressBarRef.current.style.width = 0;
  }, [plane?.media?.id]);

  return <div className="slide-panel-video-container">
    {videoProcessingFinalState === DONE ? (
      <>
        <div className="video-controller">
          <Icon name={lastVideoActionIcon} key={lastVideoActionIcon} />
          <div className="previous-frame" onClick={() => previousFrame("video-player")} />
          <div className="next-frame" onClick={() => nextFrame("video-player")} />
          <div className="toggle-play" onClick={() => pauseVideo("video-player")} />
        </div>

        <div className="video-controller-footer">
          <div className="video-controller-buttons">
            <ButtonGroup
              value=""
              options={[
                {
                  icon: "left",
                  value: "prev",
                },
                {
                  icon: isPlaying ? "pause" : "play",
                  value: "play",
                },
                {
                  icon: "right",
                  value: "next",
                },
              ]}
              variant="outline"
              size="compact"
              wrap={false}
              onChange={(value) => {
                if (value === "prev") previousFrame("footer");
                if (value === "next") nextFrame("footer");
                if (value === "play") pauseVideo("footer");
              }}
            />
          </div>
          <div className="video-progress" onClick={onClickVideoProgressBar} onMouseDown={onDragStartVideoProgressBar} ref={videoProgressRef} >
            <div className="video-progress-bar" ref={videoProgressBarRef} />
            {showExtractedFrames && extractedFrames.map(frame => {
              const currentFrame = frame?.sop_instance_uid?.split("_")?.slice(-1)[0];
              const isUnusual = frame?.to_retake;
              return !currentFrame
                ? false
                : <div className="video-progress-frame"
                  key={`frame_${currentFrame}`}
                  style={{ left: Math.min(100, (100 / (videoRef.current?.duration * frameRate) * currentFrame)) + "%" }}
                >
                  <div className="video-progress-frame-thumbnail" onClick={(e) => {
                    e.stopPropagation();
                    setIsFullScreen(false);
                    setSelectedPlaneByMediaId(frame.id);
                  }}>
                    <div className={`header-title ${isUnusual ? 'unusual' : ''}`}>
                      {frame.slide?.label[currentLanguage]}
                    </div>
                    <img
                      src={getInstanceThumbnailUri(frame.id)}
                      alt="" />
                  </div>
                </div>;
            })}
          </div>
          <div className="video-capture">
            <ButtonGroup
              options={[
                {
                  icon: "shutter",
                  label: __("examination-plane.video.extractFrame"),
                }
              ]}
              variant="outline"
              size="compact"
              wrap={false}
              disabled={!canBeCaptured}
              onChange={sendDicomWithImage}
            />
          </div>
        </div>

        <canvas id="canvas" ref={canvasRef} className={`capture-canvas`} />
        <video src={getInstanceVideoUri(plane.media.id)} autoPlay muted preload="auto" playsInline loop ref={videoRef} onLoadedData={() => onVideoLoaded()} />

        {videoExtractionFinalState !== DONE && videoExtractionFinalState !== NOT_STARTED && (
          <div className="video-status">
            {__(`examination-plane.video-extraction.${videoExtractionFinalState}`)}
          </div>
        )}
      </>
    ) : (
      <>
        <img
          className="slide-panel-video"
          src={getInstanceThumbnailUri(plane.media.id)}
          alt="" />

        {videoProcessingFinalState === NOT_STARTED || videoProcessingFinalState === IN_PROGRESS
          ?
          <div className="video-status">
            <div className="video-status-item inprogress">{__("examination-plane.video.processing")}</div>
          </div>
          :
          <div className="video-status">
            <div className="video-status-item">
              <>
                <Icon name="warning" />
                {__("examination-plane.video.process.failed")}
              </>
              <div className="retry-button" >
                <Button
                  icon="reload"
                  label={__("examination-plane.video.autoExtract")}
                  size="small"
                  onClick={processVideo}
                />
              </div>
            </div>
            <div className={`video-status-item ${(videoExtractionFinalState === IN_PROGRESS) ? "inprogress" : ""}`}>
              {__(`examination-plane.video-extraction.${videoExtractionFinalState}`)}
            </div>
          </div>}
      </>
    )}
  </div>
}

export default withTranslation()(SlidePanelVideo);
