import { withTranslation } from "react-i18next";

const ReportTemplatePrivate = ({t:__, children}) => {

  return (
    <div className="private">
      <span className="private-label"><span>{__("report.private")}</span></span>
      {children}
    </div>
  );
}

export default withTranslation()(ReportTemplatePrivate);