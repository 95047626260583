export default function ReportTemplateImage({props, placeholder, base64Images}) {
    let {columns} = (props || {});
    let {mediaIds} = (placeholder || {});

    if (!mediaIds) mediaIds = [];

    if (!columns) columns = mediaIds.length;
    columns = Number(columns);

    const mediaIdOnColumns = mediaIds.filter(m => m).reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/columns);
        resultArray[chunkIndex] = resultArray[chunkIndex] || new Array(columns).fill(false);
        resultArray[chunkIndex][index%columns] = item;
        return resultArray;
    }, []);

    return (<div className="images">
        {mediaIdOnColumns.map(row => (
            <div className="block" data-layout="columns">
                {row.map(mediaId => (
                    <div className="block">
                        {!!mediaId && !!base64Images?.[mediaId] && (
                            <img key={mediaId} src={base64Images?.[mediaId]} />
                        )}
                    </div>
                ))}
            </div>
        ))}
    </div>);
}