export default function ReportTemplateDocument({children, props}) {

  const {
    theme,
    country,
    "text-color": textColor,
    "heading-color": headingColor,
    "heading-background": headingBackground,
    "warning-color": warningColor,
  } = props;
  
  const getDocumentStyle = () => {
    const style = [];

    if (!!textColor) style.push(`--text-color: ${textColor};`);
    if (!!headingColor) style.push(`--heading-color: ${headingColor};`);
    if (!!headingBackground) style.push(`--heading-background: ${headingBackground};`);
    if (!!warningColor) style.push(`--warning-color: ${warningColor};`);

    return `.exam-report-template {${style.join("")}}`;
  }

  return (
    <>
    <style>{getDocumentStyle()}</style>
    <div className={`exam-report-template print ${headingBackground?.toLowerCase() === "transparent" ? 'transparent-heading-background' : ''}`} data-theme={theme} data-country={country}>{children}</div>
    </>
  );
}